import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>On Practices - 404 Page</title>
      <meta name='description' content='On Practices is an online editorial platform for dialogue on developing and maintaining a creative practice.' />
    </Helmet>
    <div id='four-page' className='py4 px4 tac c12 x xac'>
      <Link className='c12' to='/'>
        <h1 className='fsA four-oh4'>404</h1>
        <h1 className='fsA four-oh4'>Page not Found</h1>
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
